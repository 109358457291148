import { graphql } from 'gatsby'
import { padding, timingFunctions } from 'polished'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { RawPostExcerpt } from '../../types'
import { HomePageQuery } from '../../types/graphql-types'
import { createPostExcerpt } from '../adapters/post'
import { HomeHero } from '../components/HomeHero'
import { HomeIllustration } from '../components/HomeIllustration'
import { Layout } from '../components/Layout'
import {
  VerticalPostFeature,
  VerticalPostPropsFeature as PostProps,
} from '../components/PostFeature/VerticalPostFeature'
import { SEO } from '../components/SEO'
import { createGetPostCategory } from '../selectors/postCategory'
import { emUnit, percentCol } from '../theme/helpers/grid'
import { useResponsive } from '../theme/helpers/mediaQuery'
import { mediaQuery } from '../theme/selectors'

interface IndexPageProps {
  data: HomePageQuery
}

const Post = styled(VerticalPostFeature)`
  transition: transform 0.25s ${timingFunctions('easeOutBack')};
  &:hover {
    transform: scale(1.05);
  }
`

const StyledFeaturedPost = styled(Post)`
  grid-area: featured;
`

const PostList = styled.div`
  ${padding(null, percentCol(2), emUnit(15))}

  display: grid;
  gap: ${percentCol(1)};
  grid-template-columns: 1fr;
  grid-template-areas: 'featured' '.';

  ${mediaQuery('small')(css`
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'featured featured' '. .';
  `)}

  ${mediaQuery('large')`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'featured featured .' '. . .';
  `}
`

const FeaturedPost: React.FC<PostProps> = (props) => {
  const mediumUp = useResponsive({ breakpoint: 'medium' })
  const Component = mediumUp ? StyledFeaturedPost : Post

  return <Component featured={mediumUp} {...props} />
}

export default function ({
  data: { allPrismicPost, allPrismicPostCategory },
}: IndexPageProps) {
  const getPostCategory = createGetPostCategory(allPrismicPostCategory)
  const allPosts = allPrismicPost.edges
  const [mainPost, ...restPosts] = allPosts.reduce<RawPostExcerpt[]>(
    (acc, { node }) => {
      if (node.uid === 'etrangere') {
        acc.unshift(node)
      } else {
        acc.push(node)
      }
      return acc
    },
    [],
  )

  return (
    <Layout>
      <SEO />
      <HomeIllustration />
      <HomeHero />
      <PostList>
        <FeaturedPost
          post={createPostExcerpt(
            mainPost,
            getPostCategory(mainPost.data.category.id),
          )}
        />
        {restPosts.map((post, index) => (
          <Post
            key={`${post.uid}-${index}`}
            post={createPostExcerpt(
              post,
              getPostCategory(post.data.category.id),
            )}
          />
        ))}
      </PostList>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePage {
    allPrismicPost(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          uid
          first_publication_date
          data {
            category {
              id
            }
            title {
              text
            }
            excerpt {
              text
            }
            image {
              alt
              copyright
              fluid(maxWidth: 720) {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
              }
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
    }
    allPrismicPostCategory {
      edges {
        node {
          prismicId
          data {
            name
          }
        }
      }
    }
  }
`
