import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { emUnit } from '../../theme/helpers/grid'
import { mediaQuery } from '../../theme/selectors'
import { Sun } from './components/Sun'
import { SunReflection } from './components/SunReflection'
import { Water } from './components/Water'

const StyledSun = styled(Sun)`
  position: absolute;
  top: ${emUnit(20)};
  left: 50%;
  transform: translate3d(-50%, 0, 0) scale(0.8);

  ${mediaQuery('medium')(css`
    top: ${emUnit(25)};
    transform: translate3d(-50%, 0, 0);
  `)}
`

const StyledWater = styled(Water)`
  margin-top: ${emUnit(46)};
  width: 100%;
  z-index: 1;

  ${mediaQuery('medium')(css`
    margin-top: ${emUnit(60)};
  `)}
`

const StyledSunReflection = styled(SunReflection)`
  position: absolute;
  top: ${emUnit(50)};
  left: 50%;
  transform: translate3d(-50%, 0, 0) scale(0.8);
  transform-origin: top;
  z-index: 2;

  ${mediaQuery('medium')(css`
    top: ${emUnit(66)};
    transform: translate3d(-50%, 0, 0);
  `)}
`

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
`

const risingTide = keyframes`
  0% {
    transform: translate3d(0, 10%, 0) scaleX(2);
  }
  100% {
    transform: none;
  }
`

const sunrise = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-50%, 100%, 0);
  }
  100% {
    opacity: 1;
  }
`

const sunriseReflection = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-50%, -10%, 0);
  }
  100% {
    opacity: 1;
  }
`

export const AnimatedSunReflection = styled(StyledSunReflection)`
  animation: ${sunriseReflection} 0.5s ease-out 1.25s backwards;
`

export const AnimatedSun = styled(StyledSun)`
  animation: ${sunrise} 1.2s ease-out 0.3s backwards;
`

export const AnimatedWater = styled(StyledWater)`
  animation: ${risingTide} 1.5s ease-in-out backwards;
`

export function HomeIllustration() {
  return (
    <Wrapper>
      <AnimatedSun />
      <AnimatedWater />
      <AnimatedSunReflection />
    </Wrapper>
  )
}
