import { padding } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { emUnit, percentCol } from '../../../theme/helpers/grid'
import { mediaQuery } from '../../../theme/selectors'
import { WidowlessText } from '../../text/WidowlessText'

const StyledStatement = styled.p`
  ${padding(emUnit(4), percentCol(2))}
  text-align: center;

  ${mediaQuery('medium')`
    ${padding(emUnit(7), percentCol(5))}
  `}
`

export const Statement: React.FC<{ children: string }> = ({
  children,
  ...props
}) => (
  <WidowlessText text={children} wordCount={2}>
    {(output) => <StyledStatement {...props}>{output}</StyledStatement>}
  </WidowlessText>
)
