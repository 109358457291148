import * as React from 'react'
import styled from 'styled-components'

import { WidowlessTitle } from '../../text/WidowlessTitle'

interface TitleProps {
  children: string
}

const StyledTitle = styled(WidowlessTitle)`
  text-align: center;
`

export const Title: React.FC<TitleProps> = function(props) {
  return (
    <StyledTitle
      breakpoint="small"
      wordCount={2}
      level="h1"
      size="xxl"
      {...props}
    />
  )
}
