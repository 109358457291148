import * as React from 'react'

export const Sun: React.FC<React.SVGAttributes<SVGElement>> = function (props) {
  return (
    <svg
      width="480"
      height="480"
      viewBox="0 0 480 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M480 240.002C480 372.553 372.547 480 240 480C107.458 480 0 372.553 0 240.002C0 107.447 107.458 0 240 0C372.547 0 480 107.447 480 240.002Z"
        fill="#EEB49E"
      />
    </svg>
  )
}
