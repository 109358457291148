import { graphql, useStaticQuery } from 'gatsby'
import { padding } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { SiteMetadataQuery } from '../../../types'
import { createGetMetadata } from '../../selectors/siteMetadata'
import { emUnit, percentCol } from '../../theme/helpers/grid'
import { mediaQuery } from '../../theme/selectors'
import { Statement } from './components/Statement'
import { Title } from './components/Title'

interface HomeHeroProps {}

const Wrapper = styled.div`
  ${padding(emUnit(3), percentCol(1), emUnit(30))}

  ${mediaQuery('medium')`
    ${padding(emUnit(6), null, emUnit(39))}
  `}
`

export const HomeHero: React.FC<HomeHeroProps> = function ({}) {
  const data = useStaticQuery<SiteMetadataQuery<'bio' | 'tagline'>>(graphql`
    query HomeHeroQuery {
      site {
        siteMetadata {
          bio
          tagline
        }
      }
    }
  `)
  const getMetadata = createGetMetadata(data)
  return (
    <Wrapper>
      <Title>{getMetadata('tagline')}</Title>
      <Statement>{getMetadata('bio')}</Statement>
    </Wrapper>
  )
}
