import * as React from 'react'

export const Water: React.FC<React.SVGAttributes<SVGElement>> = function (
  props,
) {
  return (
    <svg
      viewBox="0 0 1440 1274"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1440 9.493V1273.04H0V13.174c5.488-.215 10.921-.694 16.198-1.923C51.144 3.09 89.723 2.063 126.427 1.087a3183.12 3183.12 0 0018.636-.532c86.227-2.786 172.509 8.419 258.514 19.588 10.512 1.365 21.019 2.73 31.522 4.068 36.38 4.64 72.701 8.96 108.937 11.915C682.882 47.4 820.975 34.318 958.992 21.243c26.129-2.475 52.258-4.95 78.378-7.26C1171.59 2.111 1305.77 2.78 1440 9.493z"
        fill="url(#gradient)"
      />
      <defs>
        <linearGradient
          id="gradient"
          x1="858.478"
          y1="190.5"
          x2="858.478"
          y2="1241.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0DCDE" />
          <stop offset="1" stopColor="#FAF6F2" />
        </linearGradient>
      </defs>
    </svg>
  )
}
